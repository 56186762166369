@import '../../../../scss/theme-bootstrap';

.gnav-transparent {
  // Class added to body
  // DARK style is default
  .site-header {
    background-color: transparent;
    &-formatter__inner,
    &-formatter__bar {
      background-color: transparent;
    }
    @if $cr22 {
      &-formatter__header {
        &.search-is-active {
          .site-header-formatter__bar {
            background-color: $color-white;
          }
        }
      }
    }
  }
  @if $cr22 {
    &:not(.gnav-transparent--mixed) {
      .site-header-formatter {
        &:hover {
          .site-header-formatter__inner {
            @include breakpoint($landscape-up) {
              background-color: $color-white;
            }
          }
        }
        &__inner-content {
          @include breakpoint($landscape-up) {
            max-width: 100%;
          }
          .site-header-formatter__top-container {
            @include breakpoint($landscape-up) {
              border-bottom: none;
              box-shadow: inset 0 -1px 0 0 $color-silver;
            }
          }
        }
      }
    }
  }
  // undo BG color changes for sticky nav
  &.site-header-formatter--sticky {
    .site-header-formatter__inner,
    .site-header-formatter__bar {
      background-color: $color-white;
    }
  }
  .site-content {
    margin-top: -$navbar-height;
  }

  // LIGHT style icon and link color overrides
  &.gnav-transparent--light {
    .site-header-formatter__logo svg,
    .site-header-formatter__menu-icon svg,
    .gnav-util__icon svg,
    .icon--search-large,
    .gnav-util__bag-icon {
      fill: $color-white;
    }
    .gnav-util__cart {
      color: $color-white;
    }
    .site-header-formatter__menu-icon span {
      background: $color-white;
    }
    .search-is-active {
      @if $cr22 {
        .site-header-formatter__logo svg,
        .site-header-formatter__menu-icon svg,
        .icon--search-large,
        .gnav-util__bag-icon {
          fill: $color-light-black;
        }
        .gnav-util__cart {
          color: $color-light-black;
        }
        a.header-nav-section__section-label,
        label.header-nav-section__section-label,
        .header-nav-section__section-label label,
        .menu__item-search-text,
        .site-header-formatter__custom-link a,
        .gnav-util__cart {
          @include breakpoint($landscape-up) {
            color: $color-darker-gray;
            &:hover,
            &:visited {
              box-shadow: none;
              color: $color-darker-gray;
            }
          }
        }
      }
    }
    a.header-nav-section__section-label,
    label.header-nav-section__section-label,
    .header-nav-section__section-label label,
    .menu__item-search-text,
    .site-header-formatter__custom-link a,
    .gnav-util__cart {
      @include breakpoint($landscape-up) {
        color: $color-white;
        &:hover,
        &:visited {
          box-shadow: 0 1px 0 0 $color-white;
          color: $color-white;
        }
      }
    }

    .site-header-formatter {
      &__header {
        background: transparent;
      }
      &:hover {
        .site-header-formatter__inner-content {
          // undo LIGHT color changes for light hover
          .site-header-formatter__logo svg {
            @if $cr22 {
              @include breakpoint($landscape-up) {
                fill: $color-light-black;
              }
            } @else {
              fill: $color-black;
            }
          }
          .site-header-formatter__menu-icon svg,
          .gnav-util__icon svg {
            @if $cr22 {
              @include breakpoint($landscape-up) {
                fill: $color-darker-gray;
              }
            } @else {
              fill: $color-black;
            }
          }
          .site-header-formatter__menu-icon span {
            @if $cr22 {
              background: $color-darker-gray;
            } @else {
              background: $color-black;
            }
          }
          a.header-nav-section__section-label,
          label.header-nav-section__section-label,
          .header-nav-section__section-label label,
          .menu__item-search-text,
          .site-header-formatter__custom-link a,
          .gnav-util__cart {
            @include breakpoint($landscape-up) {
              @if $cr22 {
                color: $color-darker-gray;
              } @else {
                color: $color-black;
                &:hover,
                &:visited {
                  box-shadow: 0 1px 0 0 $color-black;
                  color: $color-black;
                }
              }
            }
          }
          @if $cr22 {
            @include breakpoint($landscape-up) {
              .icon--search-large {
                fill: $color-darker-gray;
              }
              .menu__item-search-text:hover + svg.icon--search-large {
                fill: $color-light-black;
              }
            }
          }
        }
      }
    }

    // Undo Light color changes for Header Formatter Hidden.
    @if $cr22 {
      &.site-header-formatter--hidden {
        a.header-nav-section__section-label,
        label.header-nav-section__section-label,
        .header-nav-section__section-label label,
        .menu__item-search-text,
        .site-header-formatter__custom-link a,
        .gnav-util__cart {
          @include breakpoint($landscape-up) {
            color: $color-darker-gray;
          }
        }
        .site-header-formatter__logo svg {
          @include breakpoint($landscape-up) {
            fill: $color-light-black;
          }
        }
        .icon--search-large {
          @include breakpoint($landscape-up) {
            fill: $color-darker-gray;
          }
        }
      }
    }

    // undo LIGHT color changes for sticky nav
    &.site-header-formatter--sticky {
      .site-header-formatter__logo svg {
        @if $cr22 {
          fill: $color-light-black;
        } @else {
          fill: $color-black;
        }
      }
      .site-header-formatter__menu-icon svg,
      .gnav-util__icon svg {
        @if $cr22 {
          fill: $color-light-black;
          @include breakpoint($landscape-up) {
            fill: $color-darker-gray;
          }
        } @else {
          fill: $color-black;
        }
      }
      .site-header-formatter__menu-icon span {
        @if $cr22 {
          fill: $color-light-black;
          @include breakpoint($landscape-up) {
            fill: $color-darker-gray;
          }
        } @else {
          fill: $color-black;
        }
      }
      @if $cr22 {
        .icon--search-large,
        .gnav-util__cart svg {
          fill: $color-light-black;
          @include breakpoint($landscape-up) {
            fill: $color-darker-gray;
          }
        }
        .gnav-util__cart {
          .cart--bracket,
          .gnav-util__icon__cart-count,
          .gnav-util__bag-label {
            color: $color-light-black;
            @include breakpoint($landscape-up) {
              color: $color-darker-gray;
            }
          }
        }
      }
      a.header-nav-section__section-label,
      label.header-nav-section__section-label,
      .header-nav-section__section-label label,
      .menu__item-search-text,
      .site-header-formatter__custom-link a,
      .gnav-util__cart {
        @include breakpoint($landscape-up) {
          @if $cr22 {
            color: $color-darker-gray;
          } @else {
            color: $color-black;
            &:hover,
            &:visited {
              box-shadow: 0 1px 0 0 $color-black;
              color: $color-black;
            }
          }
        }
      }
    }
  }

  // Transparent gnav, dark color.
  &.gnav-transparent--dark {
    &:not(.site-header-formatter--hidden, .site-header-formatter--sticky) {
      .site-header-formatter__header {
        background: transparent;
      }
    }
    @if $cr22 {
      .site-header-formatter__logo svg,
      .gnav-util__bag-icon,
      .site-header-formatter__menu-icon svg {
        fill: $color-light-black;
      }
      .icon--search-large {
        fill: $color-light-black;
        @include breakpoint($landscape-up) {
          fill: $color-darker-gray;
        }
      }
      .gnav-util__cart {
        color: $color-light-black;
        @include breakpoint($landscape-up) {
          color: $color-darker-gray;
        }
      }
    }
  }

  // Transparent gnav, mixed icon and text colors.
  @if $cr22 {
    &.gnav-transparent--mixed {
      .site-header-formatter {
        &__utilities-item a {
          @include breakpoint($landscape-up) {
            color: $white;
          }
        }
        &:hover {
          .site-header-formatter__utilities-item a {
            @include breakpoint($landscape-up) {
              color: $color-darker-gray;
            }
          }
        }
      }
      &.site-header-formatter--sticky,
      &.site-header-formatter--hidden,
      .site-header-formatter__header.search-is-active {
        .site-header-formatter__utilities-item a {
          @include breakpoint($landscape-up) {
            color: $color-darker-gray;
          }
        }
      }
      // Adding background for the Mixed nav items on the left.
      .site-header-formatter__top {
        @include breakpoint($landscape-up) {
          transition: none;
        }
      }
      .header-nav-section__section {
        @include breakpoint($landscape-up) {
          background-color: $white;
        }
      }
      .menu__item--search {
        @include breakpoint($landscape-up) {
          align-items: center;
          background-color: $white;
          display: inline-flex;
          height: 100%;
        }
      }
      .site-header-formatter__logo {
        @include breakpoint($landscape-up) {
          background-color: $white;
          height: 100%;
        }
        .icon--logo {
          @include breakpoint($landscape-up) {
            top: calc(50% - 10px);
          }
        }
      }
    }
  }
}

// White bg always when PC gnav is 'active' or in the hover state.
.active-gnav,
.active-utility-overlay {
  // html class for active nav state
  .gnav-transparent {
    // body class
    .site-header {
      background-color: $color-white;
      &-formatter__inner,
      &-formatter__bar,
      &-formatter__trigger-reset:not(:checked) ~ .site-header-formatter__sections {
        background-color: $color-white;
      }
    }
    @if $cr22 {
      .gnav-util__cart {
        color: $color-light-black;
      }
      .icon--search-large,
      .gnav-util__bag-icon {
        fill: $color-light-black;
      }
    }
    &.gnav-transparent--light {
      .site-header-formatter__logo svg {
        @if $cr22 {
          fill: $color-light-black;
        } @else {
          fill: $color-black;
        }
      }
      .site-header-formatter__menu-icon svg,
      .gnav-util__icon svg {
        @if $cr22 {
          fill: $color-darker-gray;
        } @else {
          fill: $color-black;
        }
      }
      a.header-nav-section__section-label,
      label.header-nav-section__section-label,
      .header-nav-section__section-label label,
      .menu__item-search-text,
      .site-header-formatter__custom-link a,
      .gnav-util__cart {
        @include breakpoint($landscape-up) {
          @if $cr22 {
            color: $color-darker-gray;
          } @else {
            color: $color-black;
            &:hover,
            &:visited {
              box-shadow: 0 1px 0 0 $color-black;
              color: $color-black;
            }
          }
        }
      }
    }
  }
}
